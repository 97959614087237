<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <payment-remittance-view-header :payment-remittance="paymentRemittance" />

        <b-pagination
          v-model="currentPage"
          :total-rows="totalPages"
          :per-page="1"
          first-number
          last-number
          class="my-1"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>

        <b-card
          v-if="paymentRemittanceItem"
          class="card-general shadow-none py-1 card-dark"
        >
          <div class="d-flex flex-wrap justify-content-start general">
            <div>
              <div class="mb-0">
                Data e Hora da Solicitação
              </div>
              <h6 class="mt-1">
                {{ formatDateTimeDDMMYYHHMM(paymentRemittanceItem.signed_at) }}
              </h6>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                Contrato
              </div>
              <h6 class="mt-1">
                {{ paymentRemittanceItem.contract_code }}
                <b-badge
                  v-if="paymentRemittanceItem.tag_test !== ''"
                  pill
                  class="text-capitalize"
                  variant="warning"
                >
                  {{ paymentRemittanceItem.tag_test }}
                </b-badge>
                <b-badge
                  pill
                  class=""
                  :variant="paymentRemittanceItem.contract_person_type === 'PF' ? 'light-success' : 'light-danger'"
                >
                  {{ paymentRemittanceItem.contract_person_type }}
                </b-badge>
              </h6>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                Nome
              </div>
              <h6 class="mt-1">
                {{ paymentRemittanceItem.name }}
              </h6>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                CPF
              </div>
              <div class="mt-1">
                {{ maskHiddenCpf(paymentRemittanceItem.cpf) }}
              </div>
            </div>
            <div>
              <h6 class="text-capitalize mb-0">
                STATUS
              </h6>
              <div class="mt-1">
                <b-badge
                  pill
                  :variant="`light-${resolveStatusVariant(paymentRemittanceItem.status)}`"
                  class="text-capitalize"
                >
                  {{ paymentRemittanceItem.status_title }}
                </b-badge>
              </div>
            </div>
            <div>
              <h6 class="text-capitalize mb-0">
                PRAZO PARA PAGAMENTO
              </h6>
              <div class="mt-1">
                <b-badge
                  pill
                  class="text-capitalize"
                  :variant="`${resolveDeadlineVariant(paymentRemittanceItem.deadline_level)}`"
                >
                  {{ paymentRemittanceItem.deadline }}
                </b-badge>
              </div>
            </div>

            <div>
              <div class="text-capitalize mb-0">
                Serviço
              </div>
              <div class="mt-1">
                {{ paymentRemittanceItem.workplace_duty_type }}
              </div>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                Data Plantão
              </div>
              <h6 class="mt-1">
                {{ formatDateTimeDDMMYYHHMM(paymentRemittanceItem.start_date) }} -
                {{ formatDateTimeDDMMYYHHMM(paymentRemittanceItem.end_date) }}
              </h6>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                Instituição
              </div>
              <div class="mt-1">
                {{ paymentRemittanceItem.institution }}
              </div>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                Local
              </div>
              <div class="mt-1">
                {{ paymentRemittanceItem.workplace }}
              </div>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                Valor do serviço
              </div>
              <div class="mt-1">
                {{ formatCurrency(paymentRemittanceItem.amount_without_discount) }}
              </div>
            </div>
            <div>
              <div class="text-capitalize mb-0">
                Taxa
              </div>
              <div class="mt-1">
                {{ formatCurrency(paymentRemittanceItem.rate_value) }}
              </div>
            </div>
          </div>

          <h4 class="text-capitalize mt-3 mb-0 font-weight-normal font-medium-2">
            Autorização de Pagamento
          </h4>
          <div
            v-if="paymentRemittanceItem.company_name"
            class="mt-1"
          >
            <div><strong>Empresa:</strong> {{ truncateString(paymentRemittanceItem.company_name, 100) }}</div>
            <div><strong>CNPJ:</strong> {{ formatCnpj(paymentRemittanceItem.company_cnpj) }}</div>
          </div>

          <div class="d-flex flex-wrap justify-content-start authorization-form">
            <div class="mt-0">
              <b-form-group
                label="Banco"
                label-for="bank_name"
              >
                <b-form-input
                  id="bank_name"
                  v-model="paymentRemittanceItem.bank_name"
                  name="bank_name"
                  placeholder="Banco"
                  disabled
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Tipo Conta"
                label-for="account_type"
              >
                <v-select
                  v-model="paymentRemittanceItem.account_type"
                  :options="accountTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="account-type"
                  disabled
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Agência"
                label-for="agency"
              >
                <b-form-input
                  id="agency"
                  v-model="paymentRemittanceItem.branch_number"
                  name="agency"
                  placeholder="Agencia"
                  disabled
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Conta"
                label-for="agency"
              >
                <b-form-input
                  id="account"
                  v-model="accountNumberAndCode"
                  name="account"
                  placeholder="Conta"
                  disabled
                />
              </b-form-group>
            </div>
            <div>
              <div class="mb-0">
                Valor do pagamento
              </div>
              <h6 class="mt-1">
                {{ formatCurrency(paymentRemittanceItem.amount_with_discount) }}
              </h6>
            </div>

            <div>
              <b-form-group
                label-for="operation_code"
              >
                <label>
                  Caso tenha efetuado o pagamento<br>
                  Insira o <strong>código da operação</strong>
                </label>
                <b-form-input
                  id="operation_code"
                  v-model="paymentRemittanceItemData.operation_code"
                  name="operation_code"
                  placeholder="000000"
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                class="-mr-1"
                label="Data de pagamento"
                label-for="payment-date"
              >
                <flat-pickr
                  v-model="paymentRemittanceItemData.payment_date"
                  class="form-control"
                  :config="configDate"
                />
              </b-form-group>
            </div>
            <div class="d-flex flex-row align-items-end">
              <b-button
                class="ml-0 ml-md-1 ml-lg-0"
                type="submit"
                variant="primary"
                @click="saveItem()"
              >
                Atualizar
              </b-button>
              <b-button
                variant="danger"
                class="ml-1"
                :disabled="paymentRemittanceItem.status !== 'synchronized' "
                @click="openDelete(paymentRemittanceItem)"
              >
                Remover
              </b-button>
            </div>
          </div>
        </b-card>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-contract"
      ref="contractCancelModal"
      :title="`Deseja remover o contrato ${contractToCancel.contract_code} remessa ${contractToCancel.remittance_code}?`"
      hide-footer
      centered
    >
      <div>
        <b-alert
          show
          variant="danger"
          class="p-1"
        >
          &#x2022; Ao remover da remessa o item voltará para tela de solicitações.<br>
          &#x2022; Lembrar de cancelar item no OMIE.
        </b-alert>
        <b-form-group
          class="mt-1"
          label="Motivo"
          label-for="reopen-motive"
        >
          <b-form-textarea
            id="reopen-motive"
            v-model="contractToCancel.motive"
            rows="4"
            placeholder="Descreva o motivo..."
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="loading"
            @click="deleteItem"
          >
            <span class="d-none d-sm-inline">Remover</span>
            <feather-icon
              icon="RefreshCwIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import {
  BCard, BCardBody, BPagination, BBadge, BFormGroup, BFormInput, BButton, BModal, BFormTextarea, BAlert,
} from 'bootstrap-vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import {
  formatCnpj, formatCurrency, formatDateTimeDDMMYYHHMM, maskHiddenCpf, truncateString,
} from '@core/utils/utils'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { format, parse } from 'date-fns'
import {
  resolveDeadlineVariant,
  resolveStatusVariant,
} from '@/views/financial/payment-remittance-list/usePaymentRemittanceList'
import { orderBy } from 'lodash'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import contractStoreModule from '../contractStoreModule'
import PaymentRemittanceViewHeader from '../payment-remittance-view-table/PaymentRemittanceViewHeader.vue'

export default {
  components: {
    BAlert,
    BFormTextarea,
    BModal,
    BCard,
    BCardBody,
    BPagination,
    BBadge,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    // Local Components
    PaymentRemittanceViewHeader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      configDate: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i',
        locale: Portuguese,
        time_24hr: true,
      },
    }
  },
  methods: { formatCnpj, truncateString },
  setup() {
    const toast = useToast()

    const data = ref({})
    const currentPage = ref(1)
    const totalPages = ref(1)

    const contractCancelModal = ref(null)
    const contractToCancel = ref({})
    const loading = ref(false)
    const paymentRemittance = ref(null)
    const paymentRemittanceItem = ref(null)
    const paymentRemittanceItemData = ref({})

    const STORE_MODULE_NAME = 'app-contract'

    const resetItem = item => {
      let date = ''
      if (item.value?.payment_date) {
        date = format(new Date(item.value?.payment_date), 'dd/MM/yyyy HH:mm')
      }
      paymentRemittanceItemData.value = {
        item_id: item.value?.id || '',
        payment_date: date,
        operation_code: item.value?.operation || '',
      }
    }

    const accountNumberAndCode = computed(() => `${paymentRemittanceItem.value.account_number}-${paymentRemittanceItem.value.account_code}`)

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    watch([currentPage], () => {
      const index = currentPage.value - 1
      if (paymentRemittance.value && paymentRemittance.value.items[index]) {
        paymentRemittanceItem.value = paymentRemittance.value.items[index]
        resetItem(paymentRemittanceItem)
      }
    })

    const accountTypeOptions = [
      { label: 'CC', value: 'checking_account' },
      { label: 'CP', value: 'savings_account' },
    ]
    const sortItems = items => orderBy(items, ['name'], ['asc'])
    const fetchData = async () => {
      store.dispatch('app-contract/fetchPaymentRemittance', { id: router.currentRoute.params.id })
        .then(response => {
          paymentRemittance.value = response.data.data
          paymentRemittance.value.items = sortItems(paymentRemittance.value.items)
          // eslint-disable-next-line prefer-destructuring
          paymentRemittanceItem.value = paymentRemittance.value.items[0]
          totalPages.value = paymentRemittance.value.items.length
          currentPage.value = currentPage.value > 1 ? currentPage.value - 1 : 1
          resetItem(paymentRemittanceItem)
        })
        .catch(error => {
          if (error.response.status === 404) {
            router.push({ name: 'payment-remittance-list' })
          }
        })
    }
    fetchData()

    const saveItem = () => {
      const item = {
        item_id: paymentRemittanceItemData.value.item_id,
        payment_date: paymentRemittanceItemData.value.payment_date,
        operation_code: paymentRemittanceItemData.value.operation_code,
      }
      item.payment_date = format(parse(item.payment_date, 'dd/MM/yyyy HH:mm', new Date()), 'yyyy-MM-dd HH:mm:ss')
      store.dispatch('app-contract/updatePaymentRemittance', item)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Atualizado!',
              icon: 'CheckIcon',
              variant: 'success',
              text: '',
            },
          })
        })
        .catch(error => {
          if (error.response.status === 404) {
            //
          }
        })
    }

    async function openDelete(item) {
      const { id, contract_code } = item
      contractToCancel.value = {
        contract_code,
        remittance_code: paymentRemittance.value.code,
        payment_remittance_item_id: id,
        motive: '',
      }
      contractCancelModal.value.show()
    }

    async function deleteItem() {
      store.dispatch('app-contract/deletePaymentRemittanceItem', contractToCancel.value)
        .then(async () => {
          contractCancelModal.value.hide()
          fetchData()
          await Vue.swal({
            title: 'Removido!',
            text: 'Removido',
            icon: 'success',
            customClass: { confirmButton: 'btn btn-primary' },
            buttonsStyling: false,
          })
          contractToCancel.value = {
            payment_remittance_item_id: '',
            motive: '',
          }
        })
    }

    return {
      resolveDeadlineVariant,
      data,
      maskHiddenCpf,
      currentPage,
      totalPages,
      formatCurrency,
      formatDateTimeDDMMYYHHMM,
      paymentRemittance,
      paymentRemittanceItem,
      accountTypeOptions,
      paymentRemittanceItemData,
      saveItem,
      resolveStatusVariant,
      accountNumberAndCode,
      contractToCancel,
      loading,
      deleteItem,
      contractCancelModal,
      openDelete,
    }
  },
}
</script>

<style >
.card-dark {
  background-color: rgba(186, 191, 199, 0.12);
}
.general > div {
  padding-top: 1.5rem;
  margin-right: 50px;
}
.card-general .card-body {
  padding-top: 0;
}
.general > div > :first-child, .authorization-form > div {
  font-size: 13px;
}

.authorization-form > div {
  margin-top: 0.5rem;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.authorization-form > div > div {
  margin-bottom: 0;
}
.form-control {
  display: inline-block;
}
.form-control button {
  display: inline-block;
}

.code-input input  {
  max-width: 300px
}
.flatpickr-input {
  background-color: #fff!important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
