import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPaymentRemittance(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payment-remittance/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    contractNotInPaymentRemittance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contract/not-in-payment-remittance', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    advancedPhysicianDutiesInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/invoice/advanced-not-in-invoice', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    invoice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/invoice', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInstitutions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplaceByInstitution(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace/list-by-institution', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentRemittances(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payment-remittance', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCanceledAdvancedHistory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contract/canceled-advanced-history', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentRemittanceDeleteHistory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payment-remittance/delete-history', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportPaymentRemittance(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payment-remittance/export/${id}`, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePaymentRemittance(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/payment-remittance/delete-item/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePaymentRemittance(ctx, param) {
      return new Promise((resolve, reject) => {
        axios
          .post('/payment-remittance/manual-payment/', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateInvoice(ctx, param) {
      return new Promise((resolve, reject) => {
        axios
          .post('/invoice', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/invoice/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/invoice/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContractAdvanced(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/contract/canceled-advanced', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    payInvoice(ctx, { id, ...data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/invoice/${id}/payment`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reopenInvoice(ctx, { id, reopen_reason }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/invoice/${id}/reopen`, { reopen_reason })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/invoice/${id}/pdf`, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPaymentRemittance(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/payment-remittance', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePaymentRemittanceItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/payment-remittance/delete-item', { data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
