<template>
  <b-row
    class="statistics-row d-flex align-items-center justify-content-between"
  >
    <b-col
      md="1"
      class="mb-1"
      cols="12"
    >
      <h4 class="text-capitalize mt-0 text-left font-medium-3">
        Remessa
      </h4>
      <h4>{{ paymentRemittance ? paymentRemittance.code : '' }} </h4>
    </b-col>
    <b-col
      md="3"
      class="mb-1"
      cols="12"
    >
      <b-media no-body>
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-info"
            rounded
          >
            <feather-icon
              size="24"
              icon="UsersIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ paymentRemittance ? paymentRemittance.quantity_item : '0' }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            de itens na remessa
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col
      md="3"
      cols="12"
      class="mb-1"
    >
      <b-media no-body>
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-danger"
            rounded
          >
            <feather-icon
              size="24"
              icon="DollarSignIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ formatCurrency( paymentRemittance ? paymentRemittance.amount_without_discount : 0) }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            em valor do serviço
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col
      md="3"
      cols="12"
      class="mb-1"
    >
      <b-media no-body>
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-primary"
            rounded
          >
            <feather-icon
              size="24"
              icon="DollarSignIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ formatCurrency( paymentRemittance ? paymentRemittance.amount_with_discount : 0) }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            em valor a pagar
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col
      md="3"
      cols="12"
      class="mb-1"
    >
      <b-media no-body>
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-success"
            rounded
          >
            <feather-icon
              size="24"
              icon="DollarSignIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ formatCurrency(paymentRemittance ? paymentRemittance.rate : 0) }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            em taxas
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col
      md="1"
      class="mb-1"
      cols="12"
    >
      <b-button-group size="sm">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :to="{ name: 'payment-remittance-view'}"
          variant="outline-primary"
        >
          Visualização padrão
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :to="{ name: 'payment-remittance-view-table'}"
          variant="outline-primary"
        >
          Visualização em Tabela
        </b-button>
      </b-button-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BMedia, BCardText, BMediaBody, BAvatar, BMediaAside, BButton, BButtonGroup,
} from 'bootstrap-vue'
import { formatCurrency } from '@core/utils/utils'
import { resolveStatusVariant } from '@/views/financial/payment-remittance-list/usePaymentRemittanceList'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BCardText,
    BMediaBody,
    BAvatar,
    BMediaAside,
    BButtonGroup,
    BButton,
    // Local Components
  },
  directives: {
    Ripple,
  },
  props: {
    paymentRemittance: {
      default: null,
      required: false,
      type: Object,
    },
  },
  setup() {
    return {
      formatCurrency,
      resolveStatusVariant,
    }
  },
}
</script>

<style>
.statistics-row > div {
  max-width: 250px;
}
.statistics-row {
  margin-left: 0;
}
.btn.router-link-exact-active {
  background-color: #575756;
  color: #FFF;
}

</style>
